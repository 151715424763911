import { css } from '@emotion/react';
import { Paper } from '@mui/material';
import { memo, useCallback } from 'react';
import { VscDebugRestart } from 'react-icons/vsc';
import { useSnapshot } from 'valtio';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { useSortSignalrDataStore, useSortVirtualHoldingDataStore, } from '~/modules/symbolQuote/simple/useSortStore';
const DefaultTableHeader = memo(function DefaultTableHeader() {
    const sortSnap = useSnapshot(useSortSignalrDataStore);
    const toggleOrderKey = sortSnap.orderKey === 'desc' ? 'asc' : 'desc';
    const toggleSortKey = sortSnap.sortKey;
    const getArrow = useCallback((sortKey) => {
        return sortKey === sortSnap.sortKey ? (toggleOrderKey === 'desc' ? '▲' : '▼') : undefined;
    }, [sortSnap.sortKey, toggleOrderKey]);
    const handleColumnSelected = useCallback((sortKey) => {
        useSortSignalrDataStore.sortKey = sortKey;
        useSortSignalrDataStore.orderKey = toggleOrderKey;
    }, [toggleOrderKey]);
    return (<Paper variant={'outlined'} css={css `
        ${flex.h.crossCenter};
        width: 100%;
        height: 40px;
        cursor: pointer;
        font-size: 14px;
        user-select: none;
      `}>
      <_HeaderCell width={5} clickDelegate={() => {
            useSortSignalrDataStore.sortKey = 'none';
            useSortSignalrDataStore.orderKey = 'none';
        }}>
        <VscDebugRestart size={'20px'}/>
      </_HeaderCell>
      <_HeaderCell width={22} name='商品' arrow={getArrow('symbol')} isSelected={toggleSortKey === 'symbol'} clickDelegate={() => handleColumnSelected('symbol')}/>
      <_HeaderCell width={23} name='成交價' arrow={getArrow('close')} isSelected={toggleSortKey === 'close'} clickDelegate={() => handleColumnSelected('close')}/>
      <_HeaderCell width={25} name='漲跌' arrow={getArrow('change')} isSelected={toggleSortKey === 'change'} clickDelegate={() => handleColumnSelected('change')}/>
      <_HeaderCell width={26} name='漲跌幅%' arrow={getArrow('changePrecent')} isSelected={toggleSortKey === 'changePrecent'} clickDelegate={() => handleColumnSelected('changePrecent')}/>
    </Paper>);
});
/** 持有中標題欄位 */
const HoldingTableHeader = memo(function HoldingTableHeader() {
    const sortSnap = useSnapshot(useSortVirtualHoldingDataStore);
    const toggleOrderKey = sortSnap.orderKey === 'desc' ? 'asc' : 'desc';
    const toggleSortKey = sortSnap.sortKey;
    const getArrow = useCallback((sortKey) => {
        return sortKey === sortSnap.sortKey ? (toggleOrderKey === 'desc' ? '▲' : '▼') : undefined;
    }, [sortSnap.sortKey, toggleOrderKey]);
    const handleColumnSelected = useCallback((sortKey) => {
        useSortVirtualHoldingDataStore.sortKey = sortKey;
        useSortVirtualHoldingDataStore.orderKey = toggleOrderKey;
    }, [toggleOrderKey]);
    return (<Paper variant={'outlined'} css={css `
        ${flex.h.crossCenter};
        width: 100%;
        height: 40px;
        cursor: pointer;
        font-size: 14px;
        user-select: none;
      `}>
      <_HeaderCell width={5}/>

      <_HeaderCell width={15} name='商品' arrow={getArrow('symbol')} isSelected={toggleSortKey === 'symbol'} clickDelegate={() => {
            useSortVirtualHoldingDataStore.sortKey = 'symbol';
            useSortVirtualHoldingDataStore.orderKey = toggleOrderKey;
        }}/>

      <_HeaderCell width={20} name='成交價' arrow={getArrow('marketPrice')} isSelected={toggleSortKey === 'marketPrice'} clickDelegate={() => handleColumnSelected('marketPrice')}/>

      <_HeaderCell width={20} name='損益' arrow={getArrow('pnl')} isSelected={toggleSortKey === 'pnl'} clickDelegate={() => handleColumnSelected('pnl')}/>

      <_HeaderCell width={17.5} name='報酬率' arrow={getArrow('profitPercentage')} isSelected={toggleSortKey === 'profitPercentage'} clickDelegate={() => handleColumnSelected('profitPercentage')}/>

      <_HeaderCell width={22.5} name='進場日期' arrow={getArrow('lastModifiedDatetime')} isSelected={toggleSortKey === 'lastModifiedDatetime'} clickDelegate={() => handleColumnSelected('lastModifiedDatetime')}/>
    </Paper>);
});
/** 自選股標題欄位 */
export const WatchListHeader = memo(function WatchListHeader() {
    const sortSnap = useSnapshot(useSortSignalrDataStore);
    const toggleOrderKey = sortSnap.orderKey === 'desc' ? 'asc' : 'desc';
    const toggleSortKey = sortSnap.sortKey;
    const getArrow = useCallback((sortKey) => {
        return sortKey === sortSnap.sortKey ? (toggleOrderKey === 'desc' ? '▲' : '▼') : undefined;
    }, [sortSnap.sortKey, toggleOrderKey]);
    const handleColumnSelected = useCallback((sortKey) => {
        useSortSignalrDataStore.sortKey = sortKey;
        useSortSignalrDataStore.orderKey = toggleOrderKey;
    }, [toggleOrderKey]);
    return (<Paper variant={'outlined'} css={css `
        ${flex.h.crossCenter};
        width: 100%;
        height: 40px;
        cursor: pointer;
        font-size: 14px;
        user-select: none;
      `}>
      <_HeaderCell width={5} clickDelegate={() => {
            useSortSignalrDataStore.sortKey = 'none';
            useSortSignalrDataStore.orderKey = 'none';
        }}>
        <VscDebugRestart size={'20px'}/>
      </_HeaderCell>

      <_HeaderCell width={22} name='商品' arrow={getArrow('symbol')} isSelected={toggleSortKey === 'symbol'} clickDelegate={() => handleColumnSelected('symbol')}/>

      <_HeaderCell width={20} name='成交價' arrow={getArrow('close')} isSelected={toggleSortKey === 'close'} clickDelegate={() => handleColumnSelected('close')}/>

      <_HeaderCell width={24} name='漲跌' arrow={getArrow('change')} isSelected={toggleSortKey === 'change'} clickDelegate={() => handleColumnSelected('change')}/>

      <_HeaderCell width={24} name='漲跌幅%' arrow={getArrow('changePrecent')} isSelected={toggleSortKey === 'changePrecent'} clickDelegate={() => handleColumnSelected('changePrecent')}/>

      <_HeaderCell width={8} name='-'/>
    </Paper>);
});
const _HeaderCell = memo(function _HeaderCell({ width, clickDelegate, isSelected, arrow, name, children }) {
    return (<div css={css `
        ${flex.h.allCenter};
        width: ${width}%;
        text-decoration: ${isSelected ? 'underline' : ''};
        text-decoration-color: ${isSelected ? '#ffffff' : '#777777'};
        &:hover {
          border-radius: 5px;
        }
      `} onClick={clickDelegate}>
      {name}
      {arrow}
      {children}
    </div>);
});
export default {
    Default: DefaultTableHeader,
    Holding: HoldingTableHeader,
    WatchList: WatchListHeader,
};
