import { proxy } from 'valtio';
/** 部位持有中排序組別 */
export const useSortVirtualHoldingDataStore = proxy({
    sortKey: 'lastModifiedDatetime',
    orderKey: 'desc',
});
/** 產業排序組別 */
export const useSortSignalrIndustryDataStore = proxy({
    sortKey: '',
    orderKey: 'desc',
});
/** 一般排序組別1 */
export const useSortSignalrDataStore = proxy({
    sortKey: '',
    orderKey: 'desc',
});
/** 一般排序組別2 */
export const useSortSignalrDataStore2 = proxy({
    sortKey: '',
    orderKey: 'desc',
});
