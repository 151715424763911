import { css, keyframes } from '@emotion/react';
import { useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { useTickChanges } from '~/modules/SDK/ticktock/useTickChanges';
import { proxy, useSnapshot } from 'valtio';
export const useAnimationTypeStore = proxy({
    type: 'background',
    isDark: true,
});
export const useQuoteUpdateColorCSS = (price = 0) => {
    const state = useSnapshot(useAnimationTypeStore);
    const [updatedAnimation, setUpdatedAnimation] = useState();
    const prevPrice = useTickChanges(price);
    const animationTime = state.type === 'background' ? 500 : 1000;
    const borderUp = state.isDark === true ? '#bd2000' : '#ff0000';
    const borderDn = state.isDark === true ? '#00cc00' : '#00aa11';
    useUpdateEffect(() => {
        const quoteUpdateAnimationBackground = keyframes `
      50% {
        background-color: ${prevPrice.upThanPrevious ? '#cc000030' : '#00cc0030'};
      }
    `;
        const quoteUpdateAnimationBorder = keyframes `
      50% {
        border: 1px solid ${prevPrice.upThanPrevious ? borderUp : borderDn};
      }
    `;
        setUpdatedAnimation(css `
      animation: ${state.type === 'background'
            ? quoteUpdateAnimationBackground
            : quoteUpdateAnimationBorder}
        1s ease 1;
      label: ${useQuoteUpdateColorCSS.name +
            '--' +
            (prevPrice.upThanPrevious ? 'priceUp' : 'priceDown')};
    `);
        const timerId = globalThis.setTimeout(() => {
            setUpdatedAnimation(null);
        }, animationTime);
        return () => {
            globalThis.clearTimeout(timerId);
        };
    }, [price]);
    return updatedAnimation;
};
